:root {
  --lightgreen: #58ba47;
  --darkgreen: #173519;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Malgun Gothic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
  /* monospace; */
}

nav ul li {
  list-style: none;
}

.bg-img {
  background-image: url("images/Restore_Logo_Final trimmed.png");
  height: 175px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.menu-row {
  width: 30px;
}

/* Position the navbar container inside the image */
.container {
  position: absolute;
  margin: 10px;
  width: 50%;
  padding: 0%;
}

/* Navbar links */
.navbar {
  padding: 16px;
  background-color: var(--darkgreen);
  justify-content: center;
}

.nav-item a {
  color: var(--lightgreen);
  padding: 8px;
  margin: 2px;
  text-decoration: none;
  font-size: smaller;
  font-variant: petite-caps;
  font-weight: bold;
}

.nav-item a:hover {
  background-color: white;
  color: var(--darkgreen);
  border-radius: 10px;
}

/* .dropright {
  padding-left: 15px;
}

#dropdownMenuButton {
  background-color: var(--darkgreen);
  font-family: "Malgun Gothic";
  text-transform: uppercase;
}

#dropdownMenuButton:hover {
  background-color: var(--lightgreen);
  color: var(--darkgreen);
}

.dropdown-menu {
  border:  var(--lightgreen) 1px solid;
}

.dropdown-menu ul li a {
  color: var(--darkgreen);
  font-variant: small-caps;
}

.dropdown-menu ul {
  padding-left: 10px;
}

.dropdown-menu ul li a:hover {
  color: var(--darkgreen);
}

.dropdown-menu ul li:hover {
  background-color:  var(--lightgreen);
} */

footer {
  text-align: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-shrink: 0;
  margin-top: 20px;
  font-size: 11px;
}

footer .row {
  display: inline;
}

footer img {
  align-self: center;
}

footer ul li {
  list-style: none;
}

footer ul {
  padding-left: 3px;
  margin: 0;
}

#insta {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 30px; /* change this to change the size*/
}

#insta:hover {
  font-size: 40px;
}

.facebook {
  font-size: 30px;
}

.facebook:hover {
  font-size: 40px;
}

footer .developer,
#deannapi {
  color: var(--lightgreen);
}

.btn {
  white-space: pre-wrap;
}

/* CONTACT ME */
.contact {
  text-align: center;
}

.contact a {
  padding-left: 7px;
  color: green;
}

.contact a:hover {
  color: limegreen;
  /* background-color: black; */
}

.map {
  text-align: center;
  margin-left: 40px;
}

.google-map iframe {
  border: var(--darkgreen) 8px double;
  width: 90%;
}

.reviews {
  width: 80%;
  margin: auto;
}

.card-review {
  width: 100%;
}

.card-review img {
  width: 40%;
}

.card-review a {
  font-size: 32px;
  font-variant: small-caps;
}

/* HOME PAGE */
.home {
  text-align: center;
  height: 100%;
}

.home-title {
  border-bottom: var(--darkgreen) 10px double;
  border-top: var(--darkgreen) 10px double;
}

h1 {
  font-family: "Malgun Gothic";
  color: var(--lightgreen);
  font-variant: small-caps;
  font-weight: bold;
}

h4 h5 {
  font-family: "Malgun Gothic";
  color: var(--darkgreen);
  /* font-weight: bold; */
}

h5 {
  font-variant: small-caps;
  font-weight: 600;
}

.card-img-top {
  width: 100%;
}

.card-body {
  width: 100%;
  text-align: center;
}

.card {
  border: var(--darkgreen) 2px solid;
  width: 30%;
  margin: 10px auto 10px auto;
}

#shop-visit:hover {
  color: var(--lightgreen);
}

.home-carousel {
  /* background-color: var(--darkgreen); */
}

.home-carousel-img {
  width: 30%;
  border: var(--darkgreen) 3px double;
}

.benefits {
  /* background-color: white; */
}

.benefits li {
  list-style: none;
}

.therapy ul li {
  font-size: small;
  list-style: none;
}

/* APPOINTMENTS */
.appt h2 {
  text-align: center;
  display: grid;
}

.row a:hover {
  color: var(--lightgreen);
}

.cancellation {
  text-align: center;
  margin: auto;
  width: 40%;
  background-color: #173519;
  color: whitesmoke;
  padding: 10px;
  border-radius: 35px;
}

#appt-modal a {
  color: white;
  font-size: 11px;
}

#appointment .modal-footer {
  align-self: center;
}

/* SESSION MENU */
h2 {
  color: var(--darkgreen);
  font-variant: small-caps;
}

.session-title {
  text-align: center;
}

.mbwk {
  border-bottom: var(--darkgreen) 10px double;
  border-top: var(--darkgreen) 10px double;
  text-align: center;
}

.mbwk h3 {
  color: var(--lightgreen);
  font-variant: small-caps;
}

.card-session {
  background-color: var(--darkgreen);
  color: var(--lightgreen);
  border: var(--lightgreen) 8px double;
}

.card-header {
  text-align: center;
  font-size: 28px;
}

/* PRODUCTS */
h5,
h6 {
  text-align: center;
}

.discode {
  /* background-color: var(--darkgreen); */
  color: var(--lightgreen);
  /* border-radius: 35px; */
}

.discode a {
  /* color: var(--darkgreen); */
}

.discode a:hover {
  color: var(--lightgreen);
}

.row {
  margin: 10px;
}

.product-box {
  width: 300px;
  text-align: center;
  border: var(--darkgreen) 2px double;
  margin: 5px auto 5px auto;
}

.product-box img {
  height: 200px;
}

button {
  background-color: var(--darkgreen);
  color: white;
  font-size: 10px;
  margin-bottom: 5px;
}

button:hover {
  background-color: var(--lightgreen);
  color: black;
}

.modal-body p {
  font-size: 12px;
  list-style-position: inside;
}

.modal-body ul li {
  text-align: left;
  font-size: 11px;
  font-family: "Malgun Gothic";
}

.modal-footer button {
  background-color: var(--darkgreen);
  color: white;
}

.modal-footer button:hover {
  background-color: var(--lightgreen);
  color: black;
}

.modal-title {
  text-align: center;
  font-weight: 700;
}

#myBtnContainer button {
  margin: 10px;
}

.container {
  overflow: hidden;
}

.show {
  display: block;
}

/* BLOG */
.blog {
  text-align: center;
  background-color: var(--darkgreen);
}

.blog .card-title {
  color: var(--lightgreen);
}

.carousel-item {
  /* border-radius: 40px; */
}

/*MEDIA QUERY FOR SMALL DESKTOP SCREENS AND SMALLER */
@media screen and (max-width: 980px) {
}

/*MEDIA QUERY FOR TABLETS AND SMALLER */
@media screen and (max-width: 768px) {
  .row {
    display: inline-block;
  }

  .product-box {
    display: inline-block;
  }
}

/*MEDIA QUERY FOR MOBILE PHONES AND SMALLER */
@media screen and (max-width: 575px) {
  .row {
    display: contents;
  }

  .card {
    width: 90%;
  }

  .product-box {
    width: 175px;
    display: inline-block;
    font-size: 11px;
    margin: inherit;
  }

  .product-box img {
    height: 125px;
  }

  .btn {
    font-size: 11px;
  }

  .nav {
    padding: 2px;
    width: 70%;
    margin-left: auto;
  }

  .bg-img {
    height: 94px;
    /* background-position: left; */
    margin: 5px;
    padding: 5px;
  }

  .cancellation {
    width: 80%;
  }
}
